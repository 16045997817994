import logo from "./logo.svg";
import "./App.css";
import Header from "./components/Header";
import Menu from "./components/Menu";
import Dashboard from "./components/Dashboard";
import Footer from "./components/Footer";
import { Route, Routes, Switch } from "react-router-dom";
import Orders from "./components/orders/Orders";
import Books from "./components/books/Books";
import SingleBook from "./components/singleBook/SingleBook";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Coupons from "./components/coupons/Coupons";

function App() {
  return (
    <div class="wrapper">
      <ToastContainer />

      <Header />
      <Menu />
      <Routes>
        <Route element={<Dashboard />} path="/" index exact />
        <Route element={<Orders />} path="/orders" exact />
        <Route element={<Books />} path="/products" exact />
        <Route element={<SingleBook />} path="/products/:id" exact />
        <Route element={<Coupons />} path="/coupons" exact />
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
