import React, { useEffect, useState } from "react";
import { json, useLocation, useNavigate } from "react-router-dom";
import "./SingleBook.css";
import { deleteBook } from "../books/BooksItem";
import { productsUrl } from "../../api";
import { useRef } from "react";
import { toast } from "react-toastify";
const SingleBook = () => {
  const [data, setData] = useState({});
  const location = useLocation();
  const history = useNavigate();
  const [bookState, setBookState] = useState({
    availableQuantity: "",
    categories: [],
    coverImage: "",
    currentPrice: 0,
    description: "",
    popularityIndex: 0,
    previousPrice: 0,
    title: "",
  });
  const title = useRef();
  const currentPrice = useRef();
  const previousPrice = useRef();

  const bookId = location.pathname.split("/")[2];
  const url = `${productsUrl}${bookId}`;
  useEffect(() => {
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setData(data.data);
        setBookState({
          ...data.data,
        });
      })
      .catch(() => {
        // toast.error("Error fetching data");
      });
  }, []);
  useEffect(() => {
    // restore scroll
    window.scrollTo(0, 0);
  });

  const changeData = (name, value) => {
    setBookState((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const EditBook = async () => {
    const Title = title.current.value;
    const CurrentPrice = currentPrice.current.value;
    const PrevPrice = previousPrice.current.value;

    fetch(productsUrl + bookId, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        title: Title,
        currentPrice: CurrentPrice,
        previousPrice: PrevPrice,
        
      }),
    })
      .then((res) => {
        toast.success("Book Updated Successfully");
        history("/products");
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });

  };
  // const dispatch=useDispatch();

  return (
    <div className="content-wrapper">
      <div className="ImageContainer ">
        <img className="img-fluid" src={data.coverImage} alt={data.title} />
      </div>
      <div className="textContainer">
        <div>
          <label htmlFor="title">Title: </label>
          <input
            ref={title}
            defaultValue={data.title}
            id="title"
            name="title"
          />
        </div>


        <div>
          <label htmlFor="currentPrice">currentPrice: </label>
          <input
            defaultValue={data.currentPrice}
            id="currentPrice"
            ref={currentPrice}
            name="currentPrice"
          />
        </div>
        <div>
          <label htmlFor="previousPrice">previousPrice: </label>
          <input
            defaultValue={data.previousPrice}
            ref={previousPrice}
            id="previousPrice"
            name="previousPrice"
          />
        </div>
        <div>
          <label htmlFor="previousPrice">Description: </label>
          {/* <input
            defaultValue={data.previousPrice}
            id="previousPrice"
            name="previousPrice"
          /> */}
          <div
            name="description"
            id="description"
            dangerouslySetInnerHTML={{ __html: data.description }}
          ></div>
        </div>
      </div>

      <div className="button-container">
        <button className="btn btn-primary" onClick={EditBook}>
          Save
        </button>
        <button
          className="btn btn-danger"
          onClick={() => {
            deleteBook(bookId);
            history("/products");
          }}
        >
          <i className="bi bi-trash"></i>
          Delete
        </button>
      </div>
    </div>
  );
};

export default SingleBook;
