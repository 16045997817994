import React, { Component } from 'react'

export default class Footer extends Component {
    render() {
        return (
           <div>
            <footer className="main-footer">
              <strong>Copyright © 2022 <a href="http://visbilitylabs.in">VisibilityLabs</a>.</strong>
              <div className="float-right d-none d-sm-inline-block">
                <b>Version</b> 0.7.0
              </div>
            </footer>
          </div>

        )
    }
}