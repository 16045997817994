import React from "react";
// import { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { addCartItem } from "../../redux/cart/cartActions";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { productsUrl } from "../../api";
import "./BookItem.css";

export const deleteBook = async (id, comes) => {
  fetch(productsUrl + id, {
    method: "DELETE",
  })
    .then((res) => {
      toast.success("Book delete successfully");
      if (comes) document.getElementById(id).style.display = "none";
    })
    .catch((err) => {
      toast.error("Something went wrong");
    });
};

function BookItem({ data }) {
  // const dispatch = useDispatch();
  // // let state = useSelector(state => state.cart);
  // function addItemToCart()
  // {
  //     dispatch(addCartItem({...data, quantity:1}));
  // }
  const navigation = useNavigate();

  return (
    <div className="book-item-container" key={data._id} id={data._id}>
      <Link to={"/products/" + data._id} style={{ textDecoration: "none" }}>
        <div className="book-item-details">
          <div className="book-item-image-container">
            <img
              className="book-item-image"
              src={data.coverImage}
              alt={data.title}
            />
          </div>
          <div className="book-title-div">
            <span className="book-item-title">{data.title}</span>
            {data.author != "" && data.author && (
              <div>
                {" "}
                by <span className="book-item-author">{data.author}</span>
              </div>
            )}
          </div>
          <div className="price-div">
            <span className="book-item-price me-2">₹{data.currentPrice}</span>
            {data.currentPrice < data.previousPrice && (
              <span className="book-item-old-price">₹{data.previousPrice}</span>
            )}
          </div>
        </div>
      </Link>
      <div className="btn-container">
        <span
          className="btn btn-primary add-to-cart-btn "
          onClick={() => navigation("/products/" + data._id)}
        >
          <i className="bi bi-edit"></i>
          Edit
        </span>
        <button
          className="btn btn-secondary"
          onClick={() => {
            deleteBook(data._id, true);
          }}
        >
          <i className="bi bi-trash"></i> Delete
        </button>
      </div>
    </div>
  );
}

export default BookItem;
