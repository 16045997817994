import React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useNavigation } from "react-router-dom";
import "./Dashboard.css";
function Dashboard() {
  const array = [
    { path: "/", name: "Dashboard" },
    { path: "/orders", name: "Orders" },
    { path: "/products", name: "Products" },
  ];

  const Navigator = useNavigate();
  return (
    <div>
      <div className="grid-container content-wrapper">
        {array.map((item, index) => {
          return (
            <div
              className={`box${index} `}
              key={item.name}
              onClick={() => {
                Navigator(item.path);
              }}
            >
              {item.name}
            </div>
          );
        })}
      </div>
    </div>
  );
}
export default Dashboard;
