import React from "react";
// import { useEffect } from "react";
import BookItem from "../books/BooksItem";
// import BookItem from "../BookItem/BookItem";
import "./page.css";
const Page = ({ posts }) => {
  // console.log(posts, "Posts are coming here");
  return (
    <>
      <div className="grid-container-in-books">
        {posts.map((item) => {
          return <BookItem key={item._id} data={item} />;
        })}
      </div>
    </>
  );
};
export default Page;
