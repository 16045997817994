import React, { useState, useEffect, useRef } from "react";
import Page from "../page/Page";
import Loader from "../loader/Loader";
import { productsUrl, searchUrl } from "../../api";

const Books = () => {
  const [bookData, setBookData] = useState([]);
  const [currPage, SetCurrPage] = useState(1);
  const [postsPerPage, setpostsPerPage] = useState(1000);
  // get params from url
  const categoryFromUrl = null;
  const [loadingArea, setLoadingArea] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(
    categoryFromUrl || "ALL"
  );

  let url =
    currentCategory == "ALL"
      ? `${productsUrl}?pageNo=${currPage}&pageSize=${postsPerPage}`
      : `${productsUrl}/category/${currentCategory}?pageNo=${currPage}&pageSize=${postsPerPage}`;

  useEffect(() => {
    // dispatch(setLoading(true));
    
    fetch(url, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        if (currPage == 1) {
          setBookData([...data.data]);
        } else {
          setBookData((prev) => [...prev, ...data.data]);
        }
      })
      .catch((err) => {});
  }, [currentCategory, currPage]);
  // console.log(bookData);
  useEffect(() => {
    if (loadingArea) {
      if (bookData != []) {
        SetCurrPage(currPage + 1);
      }
    }
  }, [loadingArea]);

  const loaderRef = useRef(null);
  const observer = new IntersectionObserver(
    (entries) => {
      if (entries[0].isIntersecting) {
        setLoadingArea(true);
      } else {
        setLoadingArea(false);
      }
    },
    { threshold: 1 }
  );
  useEffect(() => {
    if (loaderRef.current) observer.observe(loaderRef.current);
  }, []);

  const loadQueryData = async (search) => {
    // setBookData([]);
    const getData = await fetch(
      `${searchUrl}${search}`,
      {}
    ).then((res) => res.json());
    // console.log(getData);
    setBookData((prev) => getData);
  };

  return (
    <div className="content-wrapper">
      <div class="input-group mb-3">
        <input
          type="text"
          class="form-control p-2"
          placeholder="Search"
          aria-label="Search"
          aria-describedby="basic-addon2"
          id="searchData"
        />
        <div class="input-group-append">
          <button
            onClick={(e) => {
              const search = document.getElementById("searchData").value;
              //   console.log(search.value, "Search is here");
              if (search) loadQueryData(search);
            }}
            class="btn btn-primary"
            id="basic-addon2"
          >
            Search
          </button>
        </div>
      </div>
      <Page posts={bookData} key={bookData.length} />
      {/* <div ref={loaderRef} className="flex justify-center">
        Loading
      </div>
      {loadingArea && <Loader />} */}
    </div>
  );
};

export default Books;
