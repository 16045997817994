import React, { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import "./Coupons.css";
import { couponsUrl, orgId } from "../../api";
const Coupons = () => {
  const [AllCoupons, setAllCoupons] = useState([]);
  const [Modal, setModal] = useState({});
  const getCoupons = async () => {
    fetch(couponsUrl)
      .then((res) => {
        return res.json();
      })
      .then((ress) => {
        setAllCoupons(ress);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const defaultCouponData={
    code:"",
    discount:"",
    expirationDate:"",
    discountType:"",
    maxDiscount:"",
    minValue:"",
    orgId: orgId,
    active:true
  }
  const [CouponData, setCouponData] = useState({...defaultCouponData});
  useEffect(() => {
    getCoupons();
  }, []);
async function addCoupon(){
  // check for empty fields
  if(CouponData.code==""||CouponData.discount==""||CouponData.expirationDate==""||CouponData.discountType==""||CouponData.maxDiscount==""||CouponData.minValue==""){
  alert("Please fill all the fields");
    return}
  console.log(CouponData)
  fetch(couponsUrl,{
    method:"POST",
    headers:{
      "Content-Type":"application/json"
    },
    body:JSON.stringify(CouponData)
  })
  .then(res=>{
    return res.json()
  }).then(data=>{
    console.log(data)
    setAllCoupons([...AllCoupons,{...CouponData}])
  })
}
async function editCoupon(){
  // check for empty fields
  if(CouponData.code==""||CouponData.discount==""||CouponData.expirationDate==""||CouponData.discountType==""||CouponData.maxDiscount==""||CouponData.minValue==""){
  alert("Please fill all the fields");
    return}
  console.log(CouponData)
  fetch(couponsUrl+CouponData._id,{
    method:"PUT",
    headers:{
      "Content-Type":"application/json"
    },
    body:JSON.stringify(CouponData)
  })
  .then(res=>{
    return res.json()
  }).then(data=>{
    console.log(data)
    setAllCoupons(AllCoupons.map((item)=>item._id===CouponData._id?{...CouponData}:item))
  })
}
async function deleteCoupon(id){
  fetch(couponsUrl+id,{
    method:"DELETE",
    headers:{
      "Content-Type":"application/json"
    }
  })
  .then(res=>{
    return res.json()
  }
  ).then(data=>{
    console.log(data)
    setAllCoupons(AllCoupons.filter((item)=>item._id!==id))
  }
  )
}
  console.log(AllCoupons);
  return (
    <div className="content-wrapper">
      <div className="coupon-title justify-content-between d-flex">
        <span>All Coupons</span>
        <button
          type="button"
          class="btn btn-primary"
          data-toggle="modal"
          data-target="#addCoupon"
          onClick={()=>setCouponData({...defaultCouponData})}
        >
          Add Coupon
        </button>
      </div>

      <div className="all-coupons">
        <section className="content">
          <div className="container-fluid">
            <div className="row">
            
              <div class="col-12">
            
            

            <div class="card">
             
              
              <div class="card-body overflow-x-auto">
                <table id="example1" class="table table-bordered anyvair-table">
                  <thead>
                  <tr>
                    <th>Offer Code</th>
                    <th>Type</th>
                    <th>Created On</th>
                    <th>Valid till</th>
                    <th>Discount</th>
                    <th>Min Value</th>
                    <th>Max Discount</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                    {
                      AllCoupons.map(coupon=>{
                        console.log(coupon)
                        return(
                          <tr>
                      <td>{coupon.code}</td>
                      <td>{coupon.discountType}
                      </td>
                      <td>{moment(coupon.createdAt).format("DD/MM/YYYY")}</td>
                      <td >{moment(coupon.expirationDate).format("DD/MM/YYYY")}</td>
                      <td>{coupon.discount}</td>
                      <td>{coupon.minValue}</td>
                      <td>{coupon.maxDiscount}</td>
                      <td class="status-table"><span class="status" type={coupon.active?"active":"inactive"}>{coupon.active?"Active":"Inactive"}</span></td>
                      <td>
                        <div class=" dropdown">
                        <a class="table-dropdown" data-toggle="dropdown" href="#">
                          <i class="fas fa-ellipsis-v"></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right dropdown-menu-table">
                          <a href="#" class="dropdown-item" onClick={()=>deleteCoupon(coupon._id)} >
                            
                            Delete
                            
                          </a>
                          <a href="#" class="dropdown-item" data-toggle="modal" data-target="#addCoupon" onClick={()=>setCouponData({...coupon})}>
                            Edit
                          </a>
                         
                        </div>
                      </div></td>
                    </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
              
            </div>
            
          </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
      </div>
      <div
        class="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">
                {Modal?.code}
                <small> {Modal?.active ? "Active" : "Not Active"}</small>
              </h5>
              <button
                id="closeButton"
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div className="container border-bottom p-1">
                <div className="col p-1">Discount: {Modal?.discount}</div>
                <div className="col p-1">
                  DiscountType: {Modal?.discountType}
                </div>
                <div className="col p-1">
                  ExpirationTime:{" "}
                  {moment(Modal?.expirationDate, "YYYYMMDD").fromNow()}
                </div>
                <div className="col p-1">maxDiscount: {Modal.maxDiscount}</div>

                <div className="col p-1">minValue: {Modal.minValue}</div>
              </div>
              <div className="container p-1">
                {/* <div className="col p-1">Mode: {Modal?.modeOfPayment}</div>
                <div className="col p-1">Shipping: {Modal?.shipping}</div>
                <div className="col p-1">Total: {Modal?.total}</div> */}
              </div>
            </div>
          </div>
        </div>
      </div> 
      <div
        class="modal fade"
        id="addCoupon"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">
                Add Coupon
                </h5>
              <button
                id="closeButton"
                type="button"
                class="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              {/* Create a form to add coupon that changes value of CouponData */}
              <form>
                <div class="form-group
                ">
                  <label for="exampleInputEmail1">Coupon Code</label>
                  <input
                    type="text"
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={CouponData?.code}
                    onChange={(e) => {
                      setCouponData({
                        ...CouponData,
                        code: e.target.value,
                      });
                    }}
                  />

                  <label for="exampleInputEmail1">Discount</label>
                  <input
                    type="number"
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={CouponData?.discount}
                    max="100"
                    min="0"
                    onChange={(e) => {
                      setCouponData({
                        ...CouponData,
                        discount: +e.target.value,
                      });
                    }}
                  />

                  <label for="exampleInputEmail1">Min Value</label>
                  <input
                    type="number"
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={CouponData?.minValue}
                    onChange={(e) => {
                      setCouponData({
                        ...CouponData,
                        minValue: +e.target.value,
                      });
                      console.log(CouponData)
                    }}
                  />

                  <label for="exampleInputEmail1">Max Discount</label>
                  <input
                    type="number"
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={CouponData?.maxDiscount}
                    onChange={(e) => {
                      setCouponData({
                        ...CouponData,
                        maxDiscount: +e.target.value,
                      });
                    }}
                  />

                  <label for="exampleInputEmail1">Discount Type</label>
                  <input
                    type="text"
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={CouponData?.discountType}
                    onChange={(e) => {
                      setCouponData({
                        ...CouponData,
                        discountType: e.target.value,
                      });
                    }}
                  />

                  <label for="exampleInputEmail1">Expiration Date</label>
                  <input
                    type="date"
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={CouponData?.expirationDate}
                    onChange={(e) => {
                      setCouponData({
                        ...CouponData,
                        expirationDate: e.target.value,
                      });
                    }}
                  />
                  {/* Have a checkbox for active */}
                  <div class="form-check
                  ">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                      checked={CouponData?.active}
                      onChange={(e) => {
                        setCouponData({
                          ...CouponData,
                          active: e.target.checked,
                        });
                      }}
                    />
                    <label class="form-check
                    " for="flexCheckDefault">
                      Active
                      </label>
                      </div>
                  </div>
                  <button
                    type="submit"
                    class="btn btn-primary"
                    data-dismiss="modal"
                    onClick={(e) => {
                      e.preventDefault();
                      if(CouponData._id)
                      {
                        editCoupon();
                      }
                      else
                      addCoupon();
                    }}
                  >
                    Submit
                  </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Coupons;
