import React from "react";
import "./Orders.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import { ordersUrl } from "../../api";
// import Book from "../Book";

const Orders = () => {
  const [Orders, setOrders] = useState([]);
  const [Modal, setModal] = useState({});
  const [ActiveForStatus, setActiveForStatus] = useState(null);
  const tabs=[
    {
      name:"All ",
      color:"",
      code:"all"
    },
    {
      name:"Pending ",
      code:"pending",
      color:"blue"
    },
    {
      name:"Shipped ",
      code:"shipped",
      color:"yellow"
    },
    {
      code:"delivered",
      name:"Delivered ",
      color:"green"
    },
    {
      code:"cancelled",
      name:"Cancelled ",
      color:"red"
    }
  ]
  const [ActiveTab,setActiveTab]=useState(0)
  // const navigate = useNavigation();
  const navigation = useNavigate();
  useEffect(() => {
    fetch(ordersUrl, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setOrders(data);
        console.log("done loading", data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const DeleteOrders = async (id) => {
    try {
      const deleteOrder = await fetch(
        ordersUrl + id,
        {
          method: "DELETE",
        }
      ).then(() => {
        setOrders(Orders.filter((order) => order._id !== id));});
      console.log(deleteOrder);
    } catch (error) {}
  };
  const changeStatus=async (status)=>{
    try {
      const changeStatus = await fetch(
        ordersUrl + ActiveForStatus._id,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({status:status}),
        }
      )
      .then(()=>{
        Orders[Orders.findIndex((order)=>order._id===ActiveForStatus._id)].status=status
        setOrders([...Orders])
      });
      console.log(changeStatus);
    } catch (error) {}
  }

  

const TableWithoutStatus=({filter})=>{
  console.log(filter)
  return (
    <table id="example1" className="table data-table table-bordered anyvair-table">
                  <thead>
                  <tr>
                    <th>Order Id</th>
                    <th>Customer Name</th>
                    <th>Number of Products</th>
                    <th>Total</th>
                    <th>Order Date</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  {/* <tr>
                        <td></td>
                        <td>Payment not Working
                        </td>
                        <td>Nov 20, 2021  12:51 AM</td>
                        <td ><span className="priority" type="High">High</span></td>
                        <td className="status-table"><span className="status" type="submitted">Submitted</span></td>
                        <td>
                          <button className="btn view-details">View Details</button>
                          <div className=" dropdown">
                          <a className="table-dropdown" data-toggle="dropdown" href="#">
                            <i className="fas fa-ellipsis-v"></i>
                          </a>
                          <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right dropdown-menu-table">
                            <a href="#" className="dropdown-item">
                              
                              Edit Ticket
                              
                            </a>
                            <a href="#" className="dropdown-item">
                              
                              Withdraw Ticket
                              
                            </a>
                            <a href="#" className="dropdown-item">
                              Change Priority
                            </a>
                           
                          </div>
                        </div></td>
                      </tr> */}
                    {
                      Orders.filter(order=>order.status==filter).map((item) => {
                        console.log(item)
                      return(
                      <tr>
                        <td>{item._id}</td>
                        <td>{item.address.name}
                        </td>
                        <td className="status-table">{item.cartItems.length}</td>
                        <td >{item.finalTotal}</td>
                        <td> {moment(item.createdAt, "YYYYMMDD").format("DD/MM/YYYY")}</td>
                        <td>
                        <button
                                type="button"
                                className="btn btn-primary view-details"
                                data-bs-toggle="modal"
                                data-bs-target="#staticBackdrop"
                                onClick={() => {
                                  // GetBooks(order.cartItems);
                                  setModal(item);
                                }}
                              >View Details</button>
                          <div className=" dropdown">
                          <a className="table-dropdown" data-toggle="dropdown" href="#">
                            <i className="fas fa-ellipsis-v"></i>
                          </a>
                          <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right dropdown-menu-table">
                          <a href="#" className="dropdown-item" data-toggle="modal" data-target="#modalStatus"onClick={() => setActiveForStatus(item)}>
                                
                                Change Status
                                
                              </a>
                              <a href="#" className="dropdown-item" onClick={()=>{DeleteOrders(item._id)}} >
                               Delete
                              </a>
                           
                          </div>
                        </div></td>
                      </tr>)
                      })
                    }
                  
                    </tbody>
                </table>
  )
}

const TableWithStatus=(data)=>{
    console.log(data)
    return (
      <table id="example1" className="table data-table table-bordered anyvair-table">
                    <thead>
                    <tr>
                      <th>Order Id</th>
                      <th>Customer Name</th>
                      <th>Number of Products</th>
                      <th>Total</th>
                      <th>Status</th>
                      <th>Order Date</th>
                      <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {/* <tr>
                          <td></td>
                          <td>Payment not Working
                          </td>
                          <td>Nov 20, 2021  12:51 AM</td>
                          <td ><span className="priority" type="High">High</span></td>
                          <td className="status-table"><span className="status" type="submitted">Submitted</span></td>
                          <td>
                            <button className="btn view-details">View Details</button>
                            <div className=" dropdown">
                            <a className="table-dropdown" data-toggle="dropdown" href="#">
                              <i className="fas fa-ellipsis-v"></i>
                            </a>
                            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right dropdown-menu-table">
                              <a href="#" className="dropdown-item">
                                
                                Edit Ticket
                                
                              </a>
                              <a href="#" className="dropdown-item">
                                
                                Withdraw Ticket
                                
                              </a>
                              <a href="#" className="dropdown-item">
                                Change Priority
                              </a>
                             
                            </div>
                          </div></td>
                        </tr> */}
                      {
                        Orders.map((item) => {
                          console.log(item)
                        return(
                        <tr>
                          <td>{item._id}</td>
                          <td>{item.address.name}
                          </td>
                          <td className="status-table">{item.cartItems.length}</td>
                          <td >{item.finalTotal}</td>
                          <td className="status-table text-transform-capitalize"><span className="status" type={item.status}>{item.status}</span></td>
                          <td> {moment(item.createdAt, "YYYYMMDD").format("DD/MM/YYYY")}</td>
                          <td>
                          <button
                                  type="button"
                                  className="btn btn-primary view-details"
                                  data-bs-toggle="modal"
                                  data-bs-target="#staticBackdrop"
                                  onClick={() => {
                                    // GetBooks(order.cartItems);
                                    setModal(item);
                                  }}
                                >View Details</button>
                            <div className=" dropdown">
                            <a className="table-dropdown" data-toggle="dropdown" href="#">
                              <i className="fas fa-ellipsis-v"></i>
                            </a>
                            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right dropdown-menu-table">
                              <a href="#" className="dropdown-item" data-toggle="modal" data-target="#modalStatus"onClick={() => setActiveForStatus(item)}>
                                
                                Change Status
                                
                              </a>
                              <a href="#" className="dropdown-item" onClick={()=>{DeleteOrders(item._id)}} >
                               Delete
                              </a>
                            </div>
                          </div></td>
                        </tr>)
                        })
                      }
                    
                      </tbody>
                  </table>
    )
  }
  return (
    <div>
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6 ">
                <h1 className="m-0 text-dark">All Orders</h1>
              </div>
              {/* /.col */}
              <div className="col-sm-6 d-flex justify-content-end">
                <button className="btn btn-primary">Add Order</button>
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </div>
        {/* /.content-header */}
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
             
              <div className="col-12">
            
            <div className="card card-primary card-outline card-outline-tabs appointments-card">
              <div className="card-header p-0 border-bottom-0">
                <ul className="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                  {
                    tabs.map((tab, index) => {
                      return (
                        <li className="nav-item">
                          <a className={`nav-link nav-link-table nav-border-${tab.color} ${index === ActiveTab ? 'active' : ''}`} onClick={()=>setActiveTab(index)} >{tab.name}
                          </a>
                        </li>
                      )
                    })
                  }
                </ul>
              </div>
              <div className="card-body">
                <div className="tab-content " id="custom-tabs-four-tabContent">
                <div className="tab-pane fade active show">
                  {ActiveTab==0&&<TableWithStatus  />
                  }
                  {
                    ActiveTab!=0&&<TableWithoutStatus  filter={tabs[ActiveTab].code} />
                  }
                    </div>
                  
                </div>
              </div>
            </div>

            
          </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
      </div>
      {
        <div
          className="modal fade"
          id="staticBackdrop"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                  {Modal?.address?.name}
                  <small> {Modal?.userId}</small>
                </h5>
                <button
                  id="closeButton"
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="container border-bottom p-1">
                  <div className="col p-1">
                    <i className="bi bi-envelope"></i> {Modal?.address?.email}
                  </div>
                  <div className="col p-1">
                    <i className="bi bi-telephone"></i> {Modal?.address?.phone}
                  </div>
                  <div className="col p-1">
                    <i className="bi bi-house"></i> {Modal?.address?.address}
                  </div>
                  <div className="col p-1">
                    <i className="bi bi-postage"></i>
                    {Modal?.address?.pin}
                  </div>
                </div>
                <div className="container p-1">
                  <div className="h5 p-1">CartItems</div>
                  <ul>
                    {Modal?.cartItems?.map((item) => {
                      return (
                        <li
                          onClick={() => {
                            document.getElementById("closeButton").click();
                            navigation("/products/" + item._id);
                          }}
                        >
                          {item.title}
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="container p-1">
                  <div className="col p-1">Placed: {Modal?.createdAt}</div>
                  <div className="col p-1">Updated: {Modal?.updatedAt}</div>
                  <div className="col p-1">Mode: {Modal?.modeOfPayment}</div>
                  <div className="col p-1">Shipping: {Modal?.shipping}</div>
                  <div className="col p-1">Total: {Modal?.total}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      } {
        <div
          className="modal fade"
          id="modalStatus"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                  {ActiveForStatus?.address?.name}
                  <small> {ActiveForStatus?.userId}</small>
                </h5>
                <button
                  id="closeButton"
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  data-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="container border-bottom p-1">
                  Current Status: <span className="status" type={ActiveForStatus?.status} >{ActiveForStatus?.status}</span>
                  </div>
                <div className="container p-1">
                  Change Status to:
                  <button className="status" type="pending" data-dismiss="modal" onClick={()=>changeStatus("pending")}>Pending</button>
                  <button className="status" type="shipped" data-dismiss="modal" onClick={()=>changeStatus("shipped")}>Shipped</button>
                  <button className="status" type="delivered" data-dismiss="modal" onClick={()=>changeStatus("delivered")}>Delivered</button>
                  <button className="status" type="cancelled" data-dismiss="modal" onClick={()=>changeStatus("cancelled")}>Cancelled</button>
              </div>
              </div>
            </div>
          </div>
        </div>
        
      }
    </div>
  );
};

export default Orders;
